<template>
  <div
    v-if="tagsList.length"
    class="d-inline-flex"
  >
    <template v-for="(tag, tagIdx) in tagsList">
      <v-tooltip
        :key="`tag-tooltip-${tagIdx}`"
        :color="tag.Color"
        :style="`color: ${getContrastColor(tag.Color, 168)}`"
        open-delay="250"
        top
      >
        <template #activator="{on}">
          <v-chip
            :key="`tag-${tagIdx}`"
            :class="{'ml-1 px-1': small || xSmall, 'ml-2 px-2': large, 'ml-2 px-3': xLarge}"
            :color="tag.Color"
            :large="large"
            :small="small"
            :style="`color: ${getContrastColor(tag.Color, 168)}`"
            :x-large="xLarge"
            :x-small="xSmall"
            label
            v-on="on"
          >
            <v-icon
              v-if="tag.Icon && !tag.LabelOnly"
              :class="{'mr-1': (small || xSmall) && !iconOnly, 'mr-2': (large || xLarge) && !iconOnly}"
              :color="getContrastColor(tag.Color, 168)"
              :large="large"
              :small="small"
              :x-large="xLarge"
              :x-small="xSmall"
              style="pointer-events: none;"
            >
              {{ tag.Icon }}
            </v-icon>

            <span
              v-if="!iconOnly || tag.LabelOnly"
              style="pointer-events: none;"
            >{{ getTranslatedField(tag, 'Label') }}</span>
          </v-chip>
        </template>

        <div>
          <div class="text-center font-weight-bold">
            {{ getTranslatedField(tag, 'Label') }}
          </div>
          <div class="caption">
            {{ getTranslatedField(tag, 'Description') }}
          </div>
        </div>
      </v-tooltip>
    </template>
  </div>
</template>
<script>

import Translatable from '@/mixins/translatable'
import { getContrastColor } from '@/lib/utils/color'

export default {
  name      : 'ProductTags',
  components: {},
  directives: {},
  mixins    : [Translatable],
  props     : {
    tags: {
      type   : Array,
      default: undefined
    },
    iconOnly: {
      type   : Boolean,
      default: false
    },
    xSmall: {
      type   : Boolean,
      default: false
    },
    small: {
      type   : Boolean,
      default: false
    },
    large: {
      type   : Boolean,
      default: false
    },
    xLarge: {
      type   : Boolean,
      default: false
    }
  },
  data () {
    return {}
  },
  computed: {
    tagsList () {
      return this.tags?.filter(tag => tag.Active && tag.Visible && !tag.System) || []
    }
  },
  watch: {},
  beforeCreate () {
  },
  created () {
  },
  beforeMount () {
  },
  mounted () {
  },
  beforeUpdate () {
  },
  updated () {
  },
  beforeDestroy () {
  },
  destroyed () {
  },
  methods: {
    getContrastColor
  }
}
</script>

<style scoped>

</style>
