import OrderTypeEnum from '@/api/enums/OrderTypeEnum'

export default {
  name    : 'StoreCommon',
  computed: {},
  methods : {
    storeIsClosedById (id) {
      const store = this.getStoreById(id)
      return this.storeIsClosed(store)
    },
    storeIsClosed (store) {
      const supportsDelivery = !!this.$dataStore?.App?.Config?.Marketplace?.SupportsDelivery
      const supportsCollection = !!this.$dataStore?.App?.Config?.Marketplace?.SupportsCollection
      const storeIsClosed = !!store?.Status?.IsClosed
      const storeHasDelivery = !!store?.Status?.HasDelivery
      const storeHasCollection = !!store?.Status?.HasCollection

      return storeIsClosed || (supportsDelivery && !storeHasDelivery) || (supportsCollection && !storeHasCollection)
    },
    storeIsOpenedById (id) {
      const store = this.getStoreById(id)
      return !this.storeIsClosed(store)
    },
    storeIsOpened (store) {
      return !this.storeIsClosed(store)
    },

    storeIsDisabledById (id) {
      return this.$dataStore?.App?.Config?.StoreClosed?.DisableStore && this.storeIsClosedById(id)
    },
    storeIsDisabled (store) {
      return this.$dataStore?.App?.Config?.StoreClosed?.DisableStore && this.storeIsClosed(store)
    },
    storeIsHiddenById (id) {
      return this.$dataStore?.App?.Config?.StoreClosed?.HideStore && this.storeIsClosedById(id)
    },
    storeIsHidden (store) {
      return this.$dataStore?.App?.Config?.StoreClosed?.HideStore && this.storeIsClosed(store)
    },

    storeDefaultTimeById (id) {
      const store = this.getStoreById(id)
      return this.storeDefaultTime(store)
    },
    storeDefaultTime (store, suffix) {
      suffix = suffix?.toUpperCase() || 'MD'
      const defaultTime = this.$dataStore?.App?.Config?.Marketplace?.DefaultOrderType === OrderTypeEnum.COLLECTION ? store?.Status?.CollectionTime || 0 : store?.Status?.DeliveryTime || 0
      return `${defaultTime}${suffix === 'XS' ? '' : ' '}${this.$t('Store.OrderTime.Suffix' + suffix?.toUpperCase() || '')}`
    },

    storeLogoById (id) {
      const store = this.getStoreById(id)
      return store.Logo || ''
    },

    storeNameById (id) {
      const store = this.getStoreById(id)
      return store.Name || ''
    },

    storeCatalogTagsById (id) {
      const store = this.getStoreById(id)
      return store.Tags || []
    },

    getStoreById (id) {
      return this.$dataStore?.App?.Stores?.find(store => parseInt(store.Id) === parseInt(id))
    }
  }
}
