<template>
  <div v-if="activeLanguages.length > 1">
    <v-menu offset-y>
      <template #activator="{on}">
        <v-btn
          :name="$t('Common.Misc.Locale')"
          class="pa-2"
          depressed
          large
          style="min-width: 0"
          v-on="on"
        >
          <v-avatar
            :size="size"
            class="rounded"
            tile
          >
            <v-img :src="lang.icon" />
          </v-avatar>
        </v-btn>
      </template>

      <v-list dense>
        <v-list-item
          v-for="lng in activeLanguages"
          :key="lng.locale"
          :disabled="lng.locale === lang.locale"
          @click="switchLanguage(lng)"
        >
          <v-list-item-avatar
            :size="size"
            class="rounded"
            tile
          >
            <img :src="lng.icon">
          </v-list-item-avatar>

          <v-list-item-content>
            <v-list-item-title>{{ lng.name }}</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list>
    </v-menu>
  </div>
</template>

<script>
import { i18n } from '@/lang/lang'

export default {
  name      : 'LangSwitch',
  components: {},
  directives: {},
  mixins    : [],
  props     : {
    size: {
      type   : [String, Number],
      default: '32'
    }
  },
  data () {
    return {}
  },
  computed: {
    routeName () {
      return this.$route.name
    },
    languages () {
      return i18n.languages || []
    },
    activeLanguages () {
      return this.languages.filter(l => l.active && l.visible) || []
    },
    lang () {
      return this.languages.find(l => l.locale === i18n.locale) || {}
    }
  },
  watch: {},
  beforeCreate () {
  },
  created () {
  },
  beforeMount () {
  },
  mounted () {
  },
  beforeUpdate () {
  },
  updated () {
  },
  beforeDestroy () {
  },
  destroyed () {
  },
  methods: {
    switchLanguage (lang) {
      setTimeout(() => {
        const r = this.$router.resolve({
          name  : this.$route.name,
          params: { ...this.$route.params, ...{ lang: lang.locale } },
          query : this.$route.query
        })
        window.location.assign(r.href.replace(/\/+$/, ''))
      }, 50)
    }
  }
}
</script>

<style scoped>

</style>
