export default {
  name    : 'AppConfig',
  computed: {
    AppConfig () {
      return this.$dataStore.App.Config
    },

    AppQrData () {
      return this.$dataStore.App.Type.QrData
    }
  }
}
