<template>
  <div>{{ $route.name }}</div>
</template>

<script>
export default {
  name      : 'PlaceHolder',
  components: {},
  directives: {},
  mixins    : [],
  props     : [],
  data () {
    return {}
  },
  computed: {},
  watch   : {},
  beforeCreate () {
  },
  created () {
  },
  beforeMount () {
  },
  mounted () {
  },
  beforeUpdate () {
  },
  updated () {
  },
  beforeDestroy () {
  },
  destroyed () {
  },
  methods: {}
}
</script>

<style scoped>

</style>
