import i18n, { getI18nDefaultLanguage, setI18nLanguage } from '@/lang/lang'

export default async (route, injection = window.Vue) => {
  const vue = injection
  const $router = vue?.$router
  const $route = route
  const langExists = i18n.messages.hasOwnProperty($route?.params?.lang) && i18n.languages?.find(l => l.locale === $route?.params?.lang)?.active
  const lang = langExists ? $route?.params?.lang : getI18nDefaultLanguage()?.locale

  if (langExists) {
    setI18nLanguage(lang)
  } else {
    $router && $router.replace({
      replace: true,
      name   : $route.matched.length ? $route.name : 'Home',
      params : { lang: lang },
      query  : $route?.query || {}
    })
  }
}
